import type { Palette } from '@mui/material'
import { type Components, alpha } from '@mui/material'

const black = '#000000'
const white = '#FFFFFF'

const actionOpacities = {
  active: 0.54,
  hover: 0.04,
  selected: 0.08,
  disabled: 0.26,
  focus: 0.12
}

const lightPalette = {
  mode: 'light',

  text: {
    primary: alpha(black, 0.87),
    secondary: alpha(black, 0.6),
    disabled: alpha(black, 0.38)
  },

  primary: {
    main: '#3E77FE',
    dark: '#2B53B1',
    light: '#6492FE',
    contrastText: white
  },

  secondary: {
    main: '#222E65',
    dark: '#172046',
    light: '#4E5783',
    contrastText: white
  },

  action: {
    activatedOpacity: actionOpacities.active,
    active: alpha(black, actionOpacities.active),
    hoverOpacity: actionOpacities.hover,
    hover: alpha(black, actionOpacities.hover),
    selectedOpacity: actionOpacities.selected,
    selected: alpha(black, actionOpacities.selected),
    disabledOpacity: actionOpacities.disabled,
    disabled: alpha(black, actionOpacities.disabled),
    disabledBackground: alpha(black, 0.12),
    focusOpacity: actionOpacities.focus,
    focus: alpha(black, actionOpacities.focus)
  },

  info: {
    main: '#00A0FF',
    dark: '#0070B2',
    light: '#33B3FF',
    contrastText: white
  },

  success: {
    main: '#03AC1E',
    dark: '#027815',
    light: '#B3E6BB',
    contrastText: white
  },

  warning: {
    main: '#FFA600',
    dark: '#b27400',
    light: '#FFB733',
    contrastText: white
  },

  error: {
    main: '#D32F2F',
    dark: '#B13C25',
    light: '#EF5350',
    contrastText: white
  },

  background: {
    paper: white,
    default: '#FAFAFA'
  },

  divider: alpha(black, 0.12),

  common: {
    black,
    white
  },

  // Custom Colors
  purple: {
    main: '#9C27B0',
    light: '#BA68C8',
    dark: '#7B1FA2',
    contrastText: white
  },
  orange: {
    main: '#EF6C00',
    light: '#FF9800',
    dark: '#D05E00',
    contrastText: white
  },
  teal: {
    main: '#00787F',
    light: '#00B3BE',
    dark: '#006268',
    contrastText: white
  },
  aqua: {
    main: '#5EACCC',
    light: '#8EC5DB',
    dark: '#388EB1',
    contrastText: white
  },
  cornflower: {
    main: '#6689E7',
    light: '#7E9CEB',
    dark: '#3463DF',
    contrastText: white
  },
  navy: {
    main: '#4A529E',
    light: '#5F67B4',
    dark: '#3D4381',
    contrastText: white
  },
  unknown: {
    main: '#E0E0E0',
    light: '#E0E0E0',
    dark: '#E0E0E0',
    contrastText: black
  }
} satisfies Partial<Palette>

export const lightPaletteComponents: Components = {
  MuiDivider: {
    styleOverrides: {
      root: {
        color: lightPalette.divider
      }
    }
  },

  MuiTablePagination: {
    styleOverrides: {
      actions: {
        color: lightPalette.primary?.main
      }
    }
  },

  MuiToggleButton: {
    styleOverrides: {
      root: {
        color: lightPalette.text?.secondary,
        '&.Mui-selected': {
          color: 'white',
          backgroundColor: lightPalette.primary?.main,
          '&:hover': {
            color: lightPalette.text?.secondary
          }
        }
      }
    }
  }
}

export default lightPalette
