import { signOut } from 'aws-amplify/auth'

import AuthPage from './AuthPage'
import MfaQrCode from './MfaQrCode'

export default function MfaSetup() {
  return (
    <AuthPage title="Multi-factor Authentication">
      <MfaQrCode isRequired onCancel={signOut} onError={signOut} />
    </AuthPage>
  )
}
