import dayjs, { type Dayjs } from 'dayjs'
import { mapKeys } from 'lodash'
import { useMemo } from 'react'

import { pluralizeWithCount } from '~/data/formatters/string'
import roundToNearestMinutes from '~/data/utils/time'
import useTimeFormatter from '~/hooks/useTimeFormatter'
import { useUserPrefs } from '~/hooks/useUserPrefs'

import type { UsePollingOptions } from './polling'
import type { MaybeDateRange, ValidatedTime } from './types'

export type ValidatedRange = {
  from: ValidatedTime
  to: ValidatedTime
  range: {
    valid: boolean
    errors: string[]
  }
}

type ValidateDateRangeOptions = {
  maxDays?: number
  minDaysAgo?: number
}

type ValidTimeMode = 'START' | 'END'

/**
 * Validates the date range and returns a rounded version of the dates.
 */
export function useValidatedDateRange(
  { from, to }: MaybeDateRange = {},
  { maxDays, minDaysAgo }: ValidateDateRangeOptions = {}
) {
  const validatedFrom = useMemo(() => validateTime(from, 'START'), [from])
  const validatedTo = useMemo(() => validateTime(to, 'END'), [to])
  const formatDateTime = useTimeFormatter()
  const { tzDayjs } = useUserPrefs()

  return useMemo<ValidatedRange>(() => {
    let rangeValid = true
    const errors = []

    if (!validatedFrom.valid) {
      rangeValid = false
      errors.push('Invalid start date')
    }

    if (!validatedTo.valid) {
      rangeValid = false
      errors.push('Invalid end date')
    }

    // If they are both valid, check the range
    if (validatedFrom.valid && validatedTo.valid) {
      const toTime = dayjs(validatedTo.time)
      const fromTime = dayjs(validatedFrom.time)

      if (toTime.isSameOrBefore(fromTime)) {
        rangeValid = false
        errors.push('Start date must be before end date')
      }

      if (maxDays && toTime.diff(fromTime, 'day') > maxDays) {
        rangeValid = false
        errors.push(`Date range must be ${pluralizeWithCount(maxDays, 'day')} or less`)
      }

      if (minDaysAgo) {
        const minDate = roundToNearestMinutes(tzDayjs().subtract(minDaysAgo, 'days').add(1, 'minutes'), 5, 'up')
        if (fromTime.isBefore(minDate) || toTime.isBefore(minDate)) {
          rangeValid = false
          errors.push(`Date range start and end must be after ${formatDateTime(minDate).dateDotTime}`)
        }
      }
    }

    return {
      from: validatedFrom,
      to: validatedTo,
      range: { valid: rangeValid, errors }
    }
  }, [validatedFrom, validatedTo, maxDays, minDaysAgo, formatDateTime, tzDayjs])
}

function validateTime(time?: Dayjs, timeMode: ValidTimeMode = 'START'): ValidatedTime {
  const isValid = time?.isValid() ?? false
  const validTime = timeMode === 'START' ? time?.startOf('minute').toISOString() : time?.endOf('minute').toISOString()
  return {
    valid: isValid,
    time: (isValid && validTime) || ''
  }
}

/**
 * Combines the skip option from the hook with the skip option from the caller. If
 * either the hook or the caller wants to skip, then the query will be skipped. This
 * is useful for omitting boilerplate code in the caller, such as `skip: !siteId`.
 */
export function combineSkip(ownSkip: boolean, options?: UsePollingOptions) {
  const optionsSkip = options?.skip ?? false
  return { ...options, skip: ownSkip || optionsSkip }
}

type TransformedDictOptions = {
  caseInsensitive?: boolean
  prefix?: string | string[]
}

/**
 * Transforms the input data to a dictionary that is case- or prefix-insensitive, or both. This is
 * useful for looking up data that has a canonical key, but may be stored with variations.
 *
 * @param data The input data to be transformed to a case- or prefix-insensitive dictionary
 */
export function useTransformedDict<T>(data: Record<string, T>, options?: TransformedDictOptions) {
  // Extract from the object to avoid re-triggering `useMemo` when the caller passes in the same
  // non-memoized object
  const { caseInsensitive, prefix } = options ?? {}

  return useMemo(() => {
    const transformed = mapKeys(data, (_, key) => applyOptions(key, { caseInsensitive, prefix }))

    return new Proxy(transformed, {
      get: function (target, prop) {
        // Transform the lookup key based on options
        return target[applyOptions(prop.toString(), { caseInsensitive, prefix })]
      }
    })
  }, [data, caseInsensitive, prefix])
}

function applyOptions(key: string, options: TransformedDictOptions) {
  const { caseInsensitive, prefix = '' } = options
  const desensitized = caseInsensitive ? key.toUpperCase() : key

  const stripPrefix = (p: string) => {
    const prefixToCheck = caseInsensitive ? p.toUpperCase() : p
    return desensitized.startsWith(prefixToCheck) ? desensitized.slice(prefixToCheck.length) : desensitized
  }

  if (Array.isArray(prefix)) {
    // Try each prefix in order
    for (const p of prefix) {
      const result = stripPrefix(p)
      if (result !== desensitized) return result
    }

    // If no prefix matched, return the original key
    return desensitized
  }

  return stripPrefix(prefix)
}
