import type { BackdropProps } from '@mui/material'
import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material'

import Center from './Center'

const LOADING_MESSAGES = [
  'Reticulating splines',
  'Counting electrons',
  'Aligning electrical phases',
  'Energizing flux capacitor',
  'Assembling quarks',
  'Opening quantum tunnel',
  'Wiggling Pentaquarks',
  'Devouring Tetraquarks',
  'Calibrating Vacuum Tubes',
  'Reducing conducers',
  'Balancing valence orbitals',
  "Stressing Maxwell's tensor",
  'Harnessing eddy currents',
  'Polarizing neutrinos',
  'Calibrating thermocouples'
]

export const selectRandomLoadingMessage = () => LOADING_MESSAGES[Math.floor(Math.random() * LOADING_MESSAGES.length)]

type LoadingMessageProps = {
  loadingMessage?: string
  height?: string | number
}

export function LoadingMessage({ loadingMessage, height = '100vh' }: LoadingMessageProps) {
  const message = loadingMessage ? loadingMessage : selectRandomLoadingMessage()
  return (
    <Center.Middle height={height}>
      <Stack data-testid="loading-message" spacing={2}>
        <Center.Horizontal>
          <CircularProgress />
        </Center.Horizontal>
        <Typography>{message}</Typography>
      </Stack>
    </Center.Middle>
  )
}

export function LoadingBackdrop({ sx, ...rest }: BackdropProps) {
  return (
    <Backdrop sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, ...sx }} {...rest}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
