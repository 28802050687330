import type { Dayjs } from 'dayjs'

/**
 * Rounds a date to the nearest minute step
 * @param date - The date to round
 * @param step - The step to round to (e.g. 15 for every quarter hour)
 * @param roundingMethod - The rounding method to use (default is 'nearest')
 * @returns The rounded Dayjs object
 */
export const roundToNearestMinutes = (date: Dayjs, step: number, roundingMethod?: 'up' | 'down') => {
  const minutes = date.minute()
  let roundedMinutes: number

  if (roundingMethod === 'up') {
    roundedMinutes = Math.ceil(minutes / step) * step
  } else if (roundingMethod === 'down') {
    roundedMinutes = Math.floor(minutes / step) * step
  } else {
    roundedMinutes = Math.round(minutes / step) * step
  }

  return date.set('minute', roundedMinutes).second(0).millisecond(0)
}

export default roundToNearestMinutes
