import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { enhancedApi } from '~/data/api/client'

import reducer from './reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['dashboard']
}

const persistedReducer = persistReducer(persistConfig, reducer) as unknown as typeof reducer

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(enhancedApi.middleware)
})
export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
