import { NotificationsNone } from '@mui/icons-material'
import { Box, Button, CircularProgress, ListItemIcon, ListItemText, Stack } from '@mui/material'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { map } from 'lodash'
import { useRouter } from 'next/navigation'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Virtuoso } from 'react-virtuoso'
import CustomChip from 'src/@core/components/mui/chip'
import type { Settings } from 'src/@core/context/settingsContext'

import Center from '~/components/Center'
import Link from '~/components/Link'
import { useOverlay } from '~/components/Overlay'
import { useGetNotificationsQuery } from '~/data/api/client'
import { useOrgChargersMap } from '~/data/api/hooks/chargers'
import { usePolling } from '~/data/api/hooks/polling'
import { useOrgSitesMap } from '~/data/api/hooks/sites'
import { useOrgVehiclesMap } from '~/data/api/hooks/vehicles'
import type { Priority } from '~/data/types/notification'
import useFirstRender from '~/hooks/useFirstRender'
import routeDirectory from '~/navigation/routes'
import {
  appendOverlayNotifications,
  clearOverlayNotifications,
  setOverlayOpen,
  useNotificationOverlayOpen,
  useOverlayNotifications
} from '~/store/slices/notifications'

import NotificationsOverlay from './NotificationsOverlay'
import PriorityPill from './PriorityPill'

type Props = {
  settings: Settings
}

const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}))

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}))

const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  flex: '1 1 100%',
  overflow: 'hidden',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginBottom: theme.spacing(0.75)
}))

const MenuItemSubtitle = styled(Typography)({
  flex: '1 1 100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
})

export default function NotificationDropdown(props: Props) {
  const { settings } = props
  const [anchorEl, setAnchorEl] = useState<Element>()
  const { direction } = settings

  const router = useRouter()
  const { chargers, notifications: notificationsRoute, sites, vehicles } = routeDirectory
  const dispatch = useDispatch()
  const isNotificationOverlayOpen = useNotificationOverlayOpen()

  const [seenNotifications, setSeenNotifications] = useState<string[]>([])
  const { data: activeNotificationResponse, isLoading: isLoadingAll } = usePolling(
    useGetNotificationsQuery,
    {
      isActive: true,
      isResolved: false,
      isAcknowledged: false,
      sort: '-activeAt',
      page: 0,
      count: 50
    },
    { intervalSeconds: 60 }
  )

  const { data: criticalNotificationResponse, isLoading: isLoadingCritical } = usePolling(
    useGetNotificationsQuery,
    {
      isActive: true,
      isResolved: false,
      isAcknowledged: false,
      priority: 'Critical',
      sort: '-activeAt',
      page: 0,
      count: 100
    },
    { intervalSeconds: 60 }
  )

  const { orgChargers } = useOrgChargersMap()
  const { orgVehicles } = useOrgVehiclesMap()
  const { orgSites } = useOrgSitesMap()

  useFirstRender(() => {
    dispatch(clearOverlayNotifications())
  })

  useEffect(() => {
    const newCriticalNotifications =
      criticalNotificationResponse?.payload.filter(({ id }) => !seenNotifications.includes(id)) ?? []
    if (newCriticalNotifications.length > 0) dispatch(appendOverlayNotifications([...newCriticalNotifications]))
  }, [dispatch, criticalNotificationResponse?.payload, seenNotifications])

  const unAcknowledgedCount = activeNotificationResponse?.meta.pagination.totalItems ?? 0

  const overlayNotifications = useOverlayNotifications()
  const notificationsOverlay = useOverlay()

  useEffect(() => {
    if (isNotificationOverlayOpen) notificationsOverlay.openOverlay()
    else notificationsOverlay.closeOverlay()
  }, [isNotificationOverlayOpen, notificationsOverlay])

  const handleDropdownClose = () => setAnchorEl(undefined)

  const activeNotifications = activeNotificationResponse?.payload ?? []
  const numNotifications = activeNotifications.length
  return (
    <Fragment>
      <NotificationsOverlay
        notifications={overlayNotifications}
        onClose={() => {
          setSeenNotifications((prev) => [...prev, ...map(overlayNotifications, 'id')])
          dispatch(setOverlayOpen(false))
        }}
        {...notificationsOverlay}
      />
      <IconButton
        color="inherit"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        aria-controls="customized-menu"
      >
        <Badge
          color="error"
          variant="dot"
          invisible={!unAcknowledgedCount}
          sx={{
            '& .MuiBadge-badge': {
              top: 4,
              right: 4,
              boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.paper}`
            }
          }}
        >
          <NotificationsNone />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transitionDuration={0}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{ cursor: 'default', userSelect: 'auto', backgroundColor: 'transparent !important' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography sx={{ cursor: 'text', fontWeight: 600 }}>Notifications</Typography>
            <CustomChip
              skin="light"
              size="small"
              color="primary"
              label={`${unAcknowledgedCount} New`}
              sx={{ height: 20, fontSize: '0.75rem', fontWeight: 500, borderRadius: '10px' }}
            />
          </Box>
        </MenuItem>
        <Virtuoso
          components={{
            EmptyPlaceholder: () => {
              return (
                <Center.Middle>
                  {isLoadingAll || isLoadingCritical ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="body2">No new notifications.</Typography>
                  )}
                </Center.Middle>
              )
            },
            Item: ({ children, ...props }) => {
              return (
                <MenuItem
                  {...props}
                  divider
                  disableRipple
                  disableTouchRipple
                  sx={{ cursor: 'default', userSelect: 'auto', backgroundColor: 'transparent !important', py: 2 }}
                >
                  {children}
                </MenuItem>
              )
            }
          }}
          style={{ height: numNotifications * 61, minHeight: 61, maxHeight: 350 }}
          data={activeNotifications}
          itemContent={(index, notification) => {
            const { id, assetId, alertName, priority, assetType } = notification
            const { assetName, depotId, route } = (() => {
              if (assetType === 'Charger') {
                const charger = orgChargers[assetId]
                return {
                  assetName: charger?.chargerName ?? '',
                  depotId: charger?.depotId ?? '',
                  route: chargers
                }
              } else {
                const vehicle = orgVehicles[assetId]
                return {
                  assetName: vehicle?.vehicleNm ?? '',
                  depotId: vehicle?.homeSiteId ?? '',
                  route: vehicles
                }
              }
            })()
            return (
              <NotificationMenuItem
                key={id}
                alertName={alertName}
                assetHref={route.details(assetId)}
                assetName={assetName}
                depotHref={depotId ? sites.details(depotId) : ''}
                depotName={depotId ? orgSites[depotId]?.depotNm ?? '' : ''}
                priority={priority}
              />
            )
          }}
        />
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            py: 3.5,
            borderBottom: 0,
            cursor: 'default',
            userSelect: 'auto',
            backgroundColor: 'transparent !important',
            borderTop: (theme) => `1px solid ${theme.palette.divider}`
          }}
        >
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              router.push(notificationsRoute)
              handleDropdownClose()
            }}
          >
            View All Notifications
          </Button>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

type NotificationMenuItemProps = {
  priority: Priority
  alertName: string
  assetHref?: string
  assetName?: string
  depotHref?: string
  depotName?: string
}

const NotificationMenuItem = ({
  priority,
  alertName,
  assetHref,
  assetName,
  depotHref,
  depotName
}: NotificationMenuItemProps) => {
  return (
    <>
      <ListItemIcon sx={{ mr: 2 }}>
        <PriorityPill priority={priority} />
      </ListItemIcon>

      <ListItemText>
        <Stack>
          <MenuItemTitle>{alertName}</MenuItemTitle>
          <MenuItemSubtitle variant="caption">
            <MaybeLink href={assetHref}>{assetName}</MaybeLink>
            {assetName && depotName && ' • '}
            <MaybeLink href={depotHref}>{depotName}</MaybeLink>
          </MenuItemSubtitle>
        </Stack>
      </ListItemText>
    </>
  )
}

function MaybeLink({ href, children }: { href?: string; children: React.ReactNode }) {
  return href ? <Link href={href}>{children}</Link> : <>{children}</>
}
