import { keyBy } from 'lodash'
import { useMemo } from 'react'

import type { TimeRange } from '~/data/time/types'
import { useCurrentOrgId } from '~/store/slices/currentOrg'

import type { GetOrganizationCustomersArgs, GetOrganizationUsersArgs } from '../client'
import {
  useGetAllOcppTagsQuery,
  useGetFleetsQuery,
  useGetOrganizationCustomersQuery,
  useGetOrganizationQuery,
  useGetOrganizationUsersQuery,
  useGetTrips1Query
} from '../client'
import type { UsePollingOptions } from './polling'
import { usePolling } from './polling'
import { useCurrentUser } from './users'
import { combineSkip, useTransformedDict, useValidatedDateRange } from './utils'

/******************************************************************************
 * GET /organizations/{orgId}
 ******************************************************************************/
export function usePolledOrg(orgId?: string, options?: UsePollingOptions) {
  const result = usePolling(useGetOrganizationQuery, { id: orgId ?? '' }, combineSkip(!orgId, options))
  return { org: result.data, ...result }
}

export function useGetOrg(orgId?: string) {
  return usePolledOrg(orgId, { intervalSeconds: 0 })
}

export function useCurrentOrg() {
  return useGetOrg(useCurrentOrgId())
}

export function useActualOrg() {
  const { synopUser } = useCurrentUser()
  return useGetOrg(synopUser?.organizationId ?? '')
}

export function useIsCurrentOrgChargeManaged() {
  const { hasChargeManagement } = useCurrentOrg()?.org ?? {}
  return Boolean(hasChargeManagement)
}

/******************************************************************************
 * GET /organizations/{orgId}/customers
 ******************************************************************************/
type UseChildOrgArgs = Omit<GetOrganizationCustomersArgs, 'id'>
export function useGetPolledChildOrgs(orgId: string, args?: UseChildOrgArgs, options?: UsePollingOptions) {
  const result = usePolling(useGetOrganizationCustomersQuery, { id: orgId, ...args }, combineSkip(!orgId, options))
  return { organizations: result.data, ...result }
}

export function useGetChildOrgs(orgId: string, args?: UseChildOrgArgs) {
  return useGetPolledChildOrgs(orgId, args, { intervalSeconds: 0 })
}

export function useCurrentOrgChildOrgs(args?: UseChildOrgArgs) {
  return useGetChildOrgs(useCurrentOrgId(), args)
}

/******************************************************************************
 * GET /organizations/{orgId}/users
 ******************************************************************************/
type UseOrgUsersArgs = Omit<GetOrganizationUsersArgs, 'id'>
export function useGetPolledOrgUsers(orgId: string, args?: UseOrgUsersArgs, options?: UsePollingOptions) {
  const result = usePolling(useGetOrganizationUsersQuery, { id: orgId, ...args }, combineSkip(!orgId, options))
  return { users: result.data, ...result }
}

export function useGetOrgUsers(orgId: string, args?: UseOrgUsersArgs) {
  return useGetPolledOrgUsers(orgId, args, { intervalSeconds: 0 })
}

export function useGetCurrentOrgUsers(args?: UseOrgUsersArgs) {
  const currentOrgId = useCurrentOrgId()
  return useGetPolledOrgUsers(currentOrgId, args)
}

export function useGetCurrentOrgUsersMap(args?: UseOrgUsersArgs) {
  const { users, isLoading } = useGetCurrentOrgUsers(args)
  return useMemo(() => {
    return { users: keyBy(users, 'id'), isLoading }
  }, [users, isLoading])
}

/******************************************************************************
 * GET /organizations/{orgId}/fleets
 ******************************************************************************/
export function usePolledOrgFleets(orgId: string, options?: UsePollingOptions) {
  const result = usePolling(useGetFleetsQuery, { id: orgId }, combineSkip(!orgId, options))
  return { fleets: result.data, ...result }
}

export function useOrgFleets(orgId: string) {
  return usePolledOrgFleets(orgId, { intervalSeconds: 0 })
}

export function useCurrentOrgFleets() {
  const currentOrgId = useCurrentOrgId()
  return usePolledOrgFleets(currentOrgId)
}

/******************************************************************************
 * GET /organizations/{orgId}/trips
 ******************************************************************************/
export function useOrgTrips(organizationId: string, timeRange: TimeRange, vehicleIds: string[]) {
  const { from, to } = useValidatedDateRange(timeRange)
  const result = usePolling(
    useGetTrips1Query,
    { id: organizationId, from: from.time, to: to.time, vehicleIds },
    { skip: !organizationId || !from.valid || !to.valid || vehicleIds.length === 0, intervalSeconds: 0 }
  )

  return { trips: result.data, ...result }
}

/******************************************************************************
 * COMBO
 ******************************************************************************/

export function useGetOrgAndChildOrgs(orgId: string, args?: Omit<GetOrganizationCustomersArgs, 'id'>) {
  const { org, isLoading: isOrgsLoading } = useGetOrg(orgId)
  const { organizations, isLoading: isCustomersLoading } = useGetChildOrgs(orgId, args)

  const combinedOrgs = useMemo(() => {
    if (!org || !organizations) return undefined
    return [org, ...organizations]
  }, [org, organizations])

  return { orgs: combinedOrgs, isLoading: isOrgsLoading || isCustomersLoading }
}

export function useGetCurrentOrgAndChildOrgs(args?: Omit<GetOrganizationCustomersArgs, 'id'>) {
  const currentOrgId = useCurrentOrgId()
  return useGetOrgAndChildOrgs(currentOrgId, args)
}

export function useGetCurrentOrgAndChildOrgsMap(args?: Omit<GetOrganizationCustomersArgs, 'id'>) {
  const { orgs, isLoading } = useGetCurrentOrgAndChildOrgs(args)
  return useMemo(() => {
    return { orgs: keyBy(orgs, 'id'), isLoading }
  }, [orgs, isLoading])
}

/**
 * Returns a map of OCPP tag value to OCPP Tags for users and vehicles
 * @param maybeOrgId
 * @returns
 */
export function useOrgOcppTagsMap(maybeOrgId?: string) {
  const currentOrgId = useCurrentOrgId()
  const orgId = maybeOrgId ?? currentOrgId
  const { data: idTags } = useGetAllOcppTagsQuery({ id: orgId }, { skip: !orgId })

  const tagMap = useMemo(() => keyBy(idTags, 'tagId'), [idTags])
  return useTransformedDict(tagMap, { caseInsensitive: true, prefix: ['VID:', 'EVCCID:'] })
}
