import { Box, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useMeasure } from 'react-use'

import { useBannerOffsetControls } from '~/store/slices/display'

import CloakingBanner from './CloakingBanner'
import TimezoneBanner from './TimezoneBanner'

export default function Banners() {
  const setBannerOffset = useBannerOffsetControls()

  // Make the height of the banner bar available to the parent component. This is needed to offset
  // the nav drawer, app bar header and main content.
  const [ref, { height }] = useMeasure()
  useEffect(() => {
    setBannerOffset(height)
  }, [height, setBannerOffset])

  return (
    <Box
      ref={ref}
      sx={(theme) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bgcolor: 'white',
        zIndex: theme.zIndex.drawer + 1
      })}
    >
      <Stack>
        <CloakingBanner />
        <TimezoneBanner />
      </Stack>
    </Box>
  )
}
