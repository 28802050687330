import { AbilityBuilder, createMongoAbility } from '@casl/ability'

import type { UserDataType } from '~/auth/AuthProvider'

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete'
export type ACLObj = { action: Actions; subject: string }

export const buildAbilityFor = (user: UserDataType) => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility)

  // Give everyone manage-all privileges. `manage` and `all` are special keywords in CASL.
  // `manage` represents any action and `all` represents any subject.
  can('manage', 'all')

  // Permissions shared between all non-admin roles
  const sharedPermissions = () => {
    cannot('manage', 'admin')

    if (user.sites && user.sites.length > 0) {
      cannot('read', 'charger')
      cannot('read', 'site')
      cannot('read', 'vehicle')

      can('read', 'charger', { depotId: { $in: user.sites } })
      can('read', 'site', { depotId: { $in: user.sites } })
      can('read', 'vehicle', { homeSiteId: { $in: user.sites } })
    }

    cannot('create', 'charger')
    cannot('update', 'charger')

    cannot('create', 'site')
    cannot('update', 'site')

    cannot('create', 'vehicle')
    cannot('update', 'vehicle')

    cannot('create', 'loi')
    cannot('update', 'loi')

    cannot('create', 'alert')
    cannot('update', 'alert')

    cannot('create', 'site_events')
    cannot('update', 'site_events')

    cannot('create', 'charger_events')
    cannot('update', 'charger_events')

    cannot('create', 'trips')
    cannot('update', 'trips')
  }
  if (user.role !== 'admin') {
    sharedPermissions()
  }

  // Additionally modify role-based abilities
  if (user.role === 'fleet operator') {
    can('create', 'charger_events')
    can('update', 'charger_events')

    can('create', 'trips')
    can('update', 'trips')
  }

  return build()
}

export const defaultACLObj: ACLObj = {
  action: 'manage',
  subject: 'all'
}
